import React from "react";
import { Button, Modal } from "react-bootstrap";

/**
 * A modal dialog that will pop up with the status of the user's offer claim.
 *
 * @param show - Boolean state indicating if the modal should be displayed
 * @param handleClose - Function to call when the user clicks to close the modal
 * @param orderData - The data returned from the server regarding the user's offer claim
 * @returns {Element} - A modal dialog to display the status of their offer claim
 */
const ClaimStatusOverlay = ({ show, handleClose, orderData }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} size="md" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="claim-status-modal-header">
            {(orderData && orderData.giftcardListPending?.length === 0 &&
              orderData.giftcardListPending?.length === 0) ? "SUCCESS" : "PENDING"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modal-body">
            {orderData && orderData.giftcardListPending?.length === 0 &&
              orderData.giftcardListFailed?.length === 0 && (
              <p className="successful-claim-text">
              Your offer has been successfully claimed. Your new gift card(s) will
              be listed under "Recently Claimed Offers".
              </p>
            )}
            {orderData && (orderData.giftcardListPending?.length > 0 ||
              orderData.giftcardListFailed?.length > 0) && (
                <p className="unsuccessful-claim-text">
                  Some of your offers are still processing. Please check back again later.
                </p>
              )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary login-modal-button sigmar-center-element" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClaimStatusOverlay;
