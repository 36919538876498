import React from "react";
import placeholderGiftCard from "../static/GenericBlackGiftCard-800px.png";
import { Container, Row, Col, Card } from "react-bootstrap";

const ClaimedGiftCards = ({ userInfo }) => {
  const { giftcardListHistory } = userInfo;

  return (
    <Container fluid className="gcotg-claimed-gc-container">
      <Row>
        <Col>
          {(process.env.REACT_APP_THIRD_LOGIN_FIELD === "zipcode" ||
            process.env.REACT_APP_THIRD_LOGIN_FIELD === "none") && (
            <h3 className="claimed-gc-header">
              Recently Claimed Offers
            </h3>
          )}
          {process.env.REACT_APP_THIRD_LOGIN_FIELD === "ticketNum" && (
            <h3 className="claimed-gc-header">
              Offer #{userInfo?.ticketNum} has {giftcardListHistory?.length}{" "}
              gift card{giftcardListHistory?.length > 1 ? "s" : ""}
            </h3>
          )}
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        {giftcardListHistory?.flatMap((giftCard) =>
          giftCard.url.split(",").map((url, index) => (
            <Col
              md={4}
              key={`${giftCard.orderResponseId}-${index}`}
              className="card-column"
            >
              <Card>
                <a href={url} target="_blank" rel="noopener noreferrer" className="nounderline">
                  <Card.Img
                    variant="top"
                    src={
                      giftCard.productImage
                        ? giftCard.productImage
                        : placeholderGiftCard
                    }
                  />
                  <Card.Body>
                    <Card.Title>
                      {giftCard?.isNew && (
                        <span className="new-claim-label">NEW</span>
                      )}
                      ${giftCard.price} {giftCard.promotionName}
                    </Card.Title>
                    <Card.Text>
                      <span className="click-here-gc-text">
                        CLICK HERE TO VIEW
                      </span>
                      <span className="gc-fine-print">
                        <span className="gc-fine-print-line">
                          Swiped on {giftCard?.swipeDate}
                        </span>
                        <span className="gc-fine-print-line">
                          Offer # {giftCard?.ticketId}
                        </span>
                      </span>
                    </Card.Text>
                  </Card.Body>
                </a>
              </Card>
            </Col>
          ))
          )}
      </Row>
      <Row>
        <Col>
          <p className="gcotg-claimed-gc-fine-print">
            Only gift cards from the last 10 offers are shown here. We highly suggest that
            you download, print, or save the link to your gift cards so you will always
            have access to them.
          </p>
        </Col>
      </Row>
    </Container>
);
};

export default ClaimedGiftCards;
